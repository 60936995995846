@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      font-family: "Proxima Nova", system-ui, sans-serif;
    }
  }
@layer utilities {
  .animate-fade-slide {
    animation: fadeInSlide 2s ease-in-out forwards;
  }
  .animate-zoom {
    animation: zoomIn 2s ease-in-out forwards;
  }
  .animate-bounce {
    animation: bounceIn 2s ease-in-out forwards infinite;
  }
}
